<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div class="table-header">
        <h3>{{ $t('statistics.title') }}</h3>
        <div>
          <label> {{ $t(`statistics.date_ini`) }}: </label>
          <input
            class="input-filter"
            type="date"
            @keyup.enter="search"
            v-model.trim="filter.denu_fecha_ini"
            style="
              height: 39px;
              text-transform: uppercase;
              margin-left: 5pt;
              width: 175pt;
            "
          />
          <label> {{ $t(`statistics.date_fin`) }}: </label>
          <input
            class="input-filter"
            type="date"
            @keyup.enter="search"
            v-model.trim="filter.denu_fecha_fin"
            style="
              height: 39px;
              text-transform: uppercase;
              margin-left: 5pt;
              width: 175pt;
            "
          />
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_tipo_denuncia"
          >
            <option value="" activate>
              {{ $t("statistics.type") }}
            </option>
            <option value="CONSULTA">
              {{ $t("statistics.consulta") }}
            </option>
            <option value="ANONIMO">
              {{ $t("statistics.anonimo") }}
            </option>
            <option value="NOMINAL">
              {{ $t("statistics.nominal") }}
            </option>
          </select>

          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_relacion_cd"
          >
            <option value="" activate>
              {{ $t("statistics.relation") }}
            </option>
            <option
              v-for="rel in relations"
              :key="rel.rela_id"
              :value="rel.rela_id"
            >
              {{ rel.rela_nombre }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_fuente"
          >
            <option value="" activate>
              {{ $t("statistics.font") }}
            </option>
            <option
              v-for="fot in fonts"
              :key="fot.fuen_id"
              :value="fot.fuen_id"
            >
              {{ fot.fuen_nombre }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_naturaleza_cd"
          >
            <option value="" activate>
              {{ $t("statistics.nature") }}
            </option>
            <option
              v-for="nat in natures"
              :key="nat.natu_id"
              :value="nat.natu_id"
            >
              {{ nat.natu_nombre }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_departamento"
          >
            <option value="" activate>
              {{ $t("statistics.department") }}
            </option>
            <option
              v-for="dep in depa"
              :key="dep.depa_id"
              :value="dep.depa_id"
            >
              {{ dep.depa_nombre }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_sede"
          >
            <option value="" activate>
              {{ $t("statistics.sede") }}
            </option>
            <option
              v-for="sed in sede"
              :key="sed.sede_id"
              :value="sed.sede_id"
            >
              {{ sed.sede_nombre }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_pertinente"
          >
            <option value="" activate>
              {{ $t("statistics.priority") }}
            </option>
            <option value="ALTA">
              {{ $t("mUser.ocases.high") }}
            </option>
            <option value="MEDIA">
              {{ $t("mUser.ocases.medio") }}
            </option>
            <option value="BAJA">
              {{ $t("mUser.ocases.low") }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_procedente"
          >
            <option value="" activate>
              {{ $t("statistics.appropriate") }}
            </option>
            <option value="0">
              {{ $t("forms.comp.no") }}
            </option>
            <option value="1">
              {{ $t("forms.comp.yes") }}
            </option>
          </select>
          <select
            class="form-select"
            aria-label="Elija una opción"
            v-model.trim="filter.denu_estado"
          >
            <option value="" activate>
              {{ $t("statistics.state") }}
            </option>
            <option value="CREADA">
              {{ $t("statistics.analysis") }}
            </option>
            <option value="ANALISIS">
              {{ $t("statistics.decision") }}
            </option>
            <option value="DECISION">
              {{ $t("statistics.Tcomunicacion") }}
            </option>
            <option value="FINALIZADA">
              {{ $t("statistics.fin") }}
            </option>
          </select>
          <button
            class="button btn buttonSearch"
            @click="search"
            style="margin-right: 5px; margin-right: 3px; margin-top: -2px"
          >
            <i class="fas fa-search"></i>
          </button>
          <button
            class="button btn buttonSearch"
            @click="searchExport"
            style="margin-right: 5px; margin-right: 3px; margin-top: -2px"
          >
            <i class="fas fa-file"></i>
          </button>
        </div>
      </div>
      <div v-if="result">
        <h3><br><br>{{ $t("statistics.resume") }}</h3>
          <table>
            <tr>
              <th scope="col">{{ $t("statistics.total") }}:</th><td>{{ resultFilter.num_den }}</td></tr><tr>
              <th scope="col">{{ $t("statistics.ptotal") }}:</th><td>100,00%</td></tr><tr>
              <th scope="col">{{ $t("statistics.filter") }}:</th><td>{{ resultFilter.num_filtro }}</td></tr><tr>
              <th scope="col">{{ $t("statistics.pfilter") }}:</th><td>{{ resultFilter.porc_filtro }}</td>
            </tr>
        </table>
        <h4><br><br>{{  $t("statistics.pstate") }}</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t("statistics.Tanalysis") }}</th>
              <th scope="col">{{ $t("statistics.Tdecision") }}</th>
              <th scope="col">{{ $t("statistics.Tcomunicacion") }}</th>
              <th scope="col">{{ $t("statistics.Tfin") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ resultFilter.num_creada }} / {{ resultFilter.porc_creada }}
              </td>
              <td>
                {{ resultFilter.num_analisis }} /
                {{ resultFilter.porc_analisis }}
              </td>
              <td>
                {{ resultFilter.num_decision }} /
                {{ resultFilter.porc_decision }}
              </td>
              <td>
                {{ resultFilter.num_finalizada }} /
                {{ resultFilter.porc_finalizada }}
              </td>
            </tr>
          </tbody>
        </table>
        <h4><br><br>{{ $t("statistics.pfuente") }}:</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t("statistics.nomfont") }}</th>
              <th scope="col">{{ $t("statistics.numfont") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(fuen, index) in fuenfiltradas" :key="index">
              <td>{{ fuen.fuen_nombre }}</td>
              <td>{{ fuen.total }}</td>
            </tr>
          </tbody>
        </table>
        <h4><br><br>{{ $t("statistics.listdenu") }}:</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t("statistics.date") }}</th>
              <th scope="col">{{ $t("statistics.type") }}</th>
              <th scope="col">{{ $t("statistics.state") }}</th>
              <th scope="col">{{ $t("statistics.msede") }}</th>
              <th scope="col">{{ $t("statistics.mdepa") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(denu, index) in listadofiltro" :key="index">
              <td>{{ denu.denu_fecha_alta }}</td>
              <td>{{ denu.denu_tipo_denuncia }}</td>
              <p v-if="denu.denu_estado === 'CREADA'">
                {{ $t("mUser.ocases.analysis") }}
              </p>
              <p v-if="denu.denu_estado === 'ANALISIS'">
                {{ $t("mUser.ocases.decision") }}
              </p>
              <p v-if="denu.denu_estado === 'DECISION'">
                {{ $t("mUser.ocases.comunication") }}
              </p>
              <p v-if="denu.denu_estado === 'FINALIZADA'">
                {{ $t("mUser.ocases.finished") }}
              </p>
              <td>{{ denu.sede_nombre }}</td>
              <td>{{ denu.depa_nombre }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MenuVer from "../../../components/MenuVer";
import axios from "axios";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      filter: {
        denu_fecha_ini: "",
        denu_fecha_fin: "",
        denu_tipo_denuncia: "",
        denu_relacion_cd: "",
        denu_fuente: "",
        denu_naturaleza_cd: "",
        denu_pertinente: "",
        denu_procedente: "",
        denu_estado: "",
        denu_sede: "",
        denu_departamento: ""
      },
      result: false,
      resultFilter: {},
      relations: [],
      fonts: [],
      natures: [],
      depa: [],
      sede: [],
      fuenfiltradas: [],
      listadofiltro: []
    };
  },
  created() {
    this.changeBreadCrumb(16);
    this.bigDrop();
    this.getRelation();
    this.getNatures();
    this.getFonts();
    this.getDepas();
    this.getSede();
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getRelation() {
      try {
        if (colorCompany==='#00A0D1'){
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=rela&ac=listado&tipo=1`
        );        }
        else {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=rela&ac=listado`
        );
        }
        if (res.data.data) {
          this.relations = res.data.data;
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async getNatures() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=natura&ac=listado`
        );
        
        if (res.data) {
          this.natures = res.data.data;
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async getFonts() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=fuente&ac=listado`
        );
        
        if (res.data) {
          this.fonts = res.data.data;
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async getDepas() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=depa&ac=listado`
        );
        
        if (res.data) {
          this.depa = res.data.data;
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async getSede() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=sede&ac=listado`
        );
        
        if (res.data) {
          this.sede = res.data.data;
        }
        
      } catch (error) {
        console.error(error);
      }
    },    
    async search() {
      const filter = {
        denu_fecha_ini: this.filter.denu_fecha_ini,
        denu_fecha_fin: this.filter.denu_fecha_fin,
        denu_tipo_denuncia: this.filter.denu_tipo_denuncia,
        denu_relacion_cd: this.filter.denu_relacion_cd,
        denu_fuente: this.filter.denu_fuente,
        denu_naturaleza_cd: this.filter.denu_naturaleza_cd,
        denu_pertinente: this.filter.denu_pertinente,
        denu_procedente: this.filter.denu_procedente,
        denu_estado: this.filter.denu_estado,
        denu_sede: this.filter.denu_sede,
        denu_departamento: this.filter.denu_departamento
      }
      
      const res = await axios.post(
        `${this.servidor}api/emp_stats.asp?tp=emp`,
        filter
      );
      
      if (res.data) {
        this.result = true;
        this.resultFilter = res.data;
        this.fuenfiltradas = res.data.data;
      }

      const res2 = await axios.post(
        `${this.servidor}api/emp_stats2.asp?tp=emp`,
        filter
      );
      
      if (res2.data) {
        this.listadofiltro = res2.data.data;
      }
      
    },
        async searchExport() {
      const filter = `denu_fecha_ini=${this.filter.denu_fecha_ini}&denu_fecha_fin=${this.filter.denu_fecha_fin}&denu_tipo_denuncia=${this.filter.denu_tipo_denuncia}&denu_relacion_cd=${this.filter.denu_relacion_cd}&denu_fuente=${this.filter.denu_fuente}&denu_naturaleza_cd=${this.filter.denu_naturaleza_cd}&denu_pertinente=${this.filter.denu_pertinente}&denu_procedente=${this.filter.denu_procedente}&denu_estado=${this.filter.denu_estado}&denu_sede=${this.filter.denu_sede}&denu_departamento=${this.filter.denu_departamento}`;
      
      const res = `${this.servidor}api/emp_stats_xls.asp?tp=emp&${filter}`;

      window.open(res, '_blank', 'noreferrer');

},

    cleanForm() {
      this.result = false;
    },
  },
};
</script>

<style scoped>
.input-filter {
  /* padding: 0.375rem 0.75rem;
  font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* float: right; */
  margin-right: 3px;
}

.buttonSearch {
  float: none;
  background-color: #a4a9a8;
  border-radius: 5px;
  height: 39px;
  width: 39px;
  margin-left: 20pt;
}

input {
  margin-bottom: 10pt;
  font-size: 0.8em;
}

select {
  height: 39px;
  width: 15%;
  display: inline;
  margin-right: 3px;
  padding-top: 6px;
  margin: 10pt;
  font-size: 0.8em;
}

label {
  font-size: 0.8em;
}
</style>